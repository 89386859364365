.app{
    background-color: white;
}
.watercolor-page{
    background-color: white;
    overflow: hidden;
    position: relative;
}
.watercolor-page-content{
    /*background-color: white;*/
}
.header{
    /*background-color: rgba(255,192,203, .1);*/
    position: fixed;
    display: flex;
    align-items: center;
    /*height: calc(18px + 12vmin);*/
    width: 100%;
    mix-blend-mode: exclusion;
    z-index: 6;
    transition-property: transform;
    transition-duration: .2s;
    /*transition-timing-function: cubic-bezier(1,-.2,0,1.2);*/
}
.header.scrolled{
    transform: translate(calc(18px + 9vmin + 45vw));
}
/*margin-left: calc(18px + 9vmin + 45vw);*/

.header-menu {
    display: flex;
    align-items: center;
    margin-right: 0;
    transition-property: margin-right;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
}
.header-menu:hover ~ .title-name {
    /*margin-right: calc(18px + 9vmin);*/
    transform: translateX(calc(18px + 9vmin));
}
.menu-logo{
    height: calc(6px + 3vmin);
    width: calc(6px + 3vmin);
    margin: calc(6px + 3vmin);
    display: inline-block;
    box-sizing: border-box;
    border: transparent solid calc(1px + 0.5vmin);
    position: relative;
    transition: transform .4s ease-in-out;
}
.header:hover .menu-logo{
    transform: rotate3d(1, 1, 1, 360deg);
}
.menu-logo .hamburger{
    position: absolute;
    width: 100%;
    height: 15%;
    margin: 7.5% 0;
    background-color: white;
    color: white;
    font-family: "Baskerville";
    font-size: 42%;
    justify-content: center;
    border-radius: 2%;
    display: flex;
    align-items: center;
    transition: transform .2s ease-in-out;
}
.menu-logo .hamburger-top{
}
.menu-logo .hamburger-bottom{
    transform: translate(0, 500%);
    mix-blend-mode: difference;
}
.menu-logo:hover .hamburger-top{
    transform: translate(0, 200%) rotate(-45deg);
}
.menu-logo:hover .hamburger-bottom{
    transform: translate(0, 200%) rotate(45deg);
}

.header-link{
    color: white;
    text-decoration: none;
    margin-inline-end: 1em;
    width: 0;
    /*display: none;*/
}
.header-menu-item{
    overflow: hidden;
    transition: max-width .4s ease-in-out;
    max-width: 0;
    width: auto;
}
.header-menu:hover .header-menu-item {
    max-width: 25vw;
}


.header.minimal .menu-logo{
    height: calc(10px + 5vmin);
    width: calc(10px + 5vmin);
    margin: calc(4px + 2vmin);
}
.header.minimal .menu-logo .hamburger{
    background-color: transparent;
    font-size: 80%;
}
.header.minimal .menu-logo .hamburger-top::after{
    content: "Rose";
}
.header.minimal .menu-logo .hamburger-bottom::after{
    content: "Kirby";
}
.header.minimal .title-name{
    opacity: 0;
}
.header.scrolled.minimal{
    transform: none;
}

.title-name{
    color: white;
    font-size: calc(10px + 5vmin);
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    user-select: none;
    text-decoration: none;
    transition: opacity .2s ease-in-out, transform .3s ease-in-out;
}
.header.scrolled .header-menu:hover ~ .title-name {
    opacity: 0;
}

.watercolor-splash{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background-color: black;
    opacity: 0;
    pointer-events: none;
    /*transition: z-index 1s jump-end; this would be nice*/
}
/*
.watercolor-splash.isloaded{
    opacity: 0;
    pointer-events: none;
}
*/
.watercolor-section{
    width: 100%;
    display: flex;
    z-index: 1;
}
.watercolor-slides{
    flex: auto;
}
.watercolor-slide{
    width: 100%;
    /*
    min-height: 25vh;
    max-height: 250vw;*/
    display: flex;
}
.watercolor-category{
    /*background-color: blue;*/
    flex: 0 0 calc(18px + 9vmin);
}
.watercolor-category > div{
    width: calc(18px + 9vmin);
    padding-top: calc(18px + 9vmin);
}
.watercolor-category > div.stuck{
    position: fixed;
    top: 0;
}
.watercolor-category > div > h2{
    color: #333333;
    margin: 0 auto;
    font-size: calc(10px + 5vmin);
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
}
.watercolor-sheet{
    flex: 0 0 45vw;
    background-image: url('images/watercolor-background-body.png');
    background-size: 100% auto;
    background-repeat: repeat-y;
    -webkit-filter: drop-shadow(0px 0px 12px rgba(0,0,0,0.4));
    filter:         drop-shadow(0px 0px 12px rgba(0,0,0,0.4));
    clip-path: polygon(-10% -10%, 0.5% -10%, 0.5% calc(0% + 1px),
                        99.5% calc(0% + 1px), 99.5% -10%, 110% -10%,
                        110% 110%, 99.5% 110%, 99.5% calc(100% - 1px),
                        0.5% calc(100% - 1px), 0.5% 110%, -10% 110% );
    margin-top: -3px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watercolor-sidebar{
    flex: 1;
    margin-left: calc(18px + 9vmin);
    margin-right: calc(18px + 9vmin);
    display: flex;
    align-items: center;
    max-width: calc(54px + 27vmin);
}
.watercolor-sidebar h1{
    margin-block-start: 0;
    margin-block-end: calc(9px + 4.5vmin);
    font-size: 10vmin;
}
.watercolor-sidebar > div{
    max-width: 650px;
}
.watercolor-ribbons img{
  height: calc(14px + 7vmin);
  max-width: calc(18px + 9vmin);
  margin-block-start: 2em;
}

.watercolor-sheet a{
    width: 98.7%;
    height: 90vh;
    display: flex;
    justify-content: center;
    height: 90vh;
    max-height: 60vw;
    margin: 5vh 0;
}
.watercolor-image{
    max-width: 100%;
    max-height: 100%;
    mix-blend-mode: multiply;
    align-self: center;
    user-select: none;
}

.watercolor-leader{
    padding-top: calc(18px + 9vmin + 9px + 4.5vmin);
    width: 100%;
    display: flex;
    z-index: 0;
}
.watercolor-beginning{
    flex: 0 0 45vw;
    position: relative;
    padding-bottom: calc(45vw * 0.2286 + 10px + 5vmin);
    background-color: white; /*this is a workaround because of isolation nonsense*/
}
.watercolor-beginning h1{
    margin-block-start: calc(10px + 5vmin);
    margin-block-end: calc(10px + 5vmin);
    position: relative;
    z-index: 5;
    color: white;
    mix-blend-mode: exclusion;
}
.watercolor-beginning p{
    position: relative;
    z-index: 5;
    color: white;
    mix-blend-mode: exclusion;
}
.watercolor-beginning .watercolor-sheet-tip{
    width: 100%;
    height: 100%;
    max-width: 45vw;
    position: absolute;
    bottom: -1px;
    z-index: 0;
    background-image: url('images/watercolor-background-tip.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: left;
    -webkit-filter: drop-shadow(0px 0px 12px rgba(0,0,0,0.4));
    filter:         drop-shadow(0px 0px 12px rgba(0,0,0,0.4));
}
.watercolor-introduction{
    /*background-color: teal;*/
    flex: 1;
    margin-left: calc(18px + 9vmin);
    margin-right: calc(18px + 9vmin);
}
.watercolor-introduction p{
    margin-top: calc(32px + 16vmin);
}

.watercolor-follower{
    width: 100%;
    display: flex;
    z-index: 1;
}
.watercolor-ending{
    flex: 0 0 45vw;
    position: relative;
    padding-bottom: calc(18px + 9vmin);
}
.watercolor-ending .watercolor-sheet-tip{
    width: 100%;
    height: calc(45vw * 0.2286);
    padding-bottom: calc(9px + 4.5vmin);
    position: relative;
    margin-top: -2px;
    z-index: 1;
    background-image: url('images/watercolor-background-tip-flip.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top;
    -webkit-filter: drop-shadow(0px 0px 12px rgba(0,0,0,0.4));
    filter:         drop-shadow(0px 0px 12px rgba(0,0,0,0.4));
}
.watercolor-follower-link{
    display: table;
    margin: .3em 0;
}
.watercolor-conclusion{
    /*background-color: teal;*/
    flex: 1;
    margin-left: calc(18px + 9vmin);
    margin-right: calc(18px + 9vmin);
    margin-top: calc(45vw * 0.2286 + 22px + 11vmin);
}

.watercolor-pens{
    position: absolute;
    z-index: 0;
    top: calc(-16vw + 6vmin);
    right: -4vw;
    width: 64vw;
    -webkit-filter: drop-shadow(0px 0px 12px rgba(0,0,0,0.2));
    filter:         drop-shadow(0px 0px 12px rgba(0,0,0,0.2));
    user-select: none;
}

@media only screen and (max-width: 120vh) {
    .watercolor-sidebar h1{
        font-size: 5vmin;
    }
}



.rolotext {
    height: calc(1.14 * (16px + 8vmin));
    overflow:hidden;
    overflow: hidden;
}
.rolotext-list {
    margin-top: 0;
    text-align: left;
    list-style: none;
    padding-left: 0;

    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}
.rolotext-list-item {
    height: calc(16px + 8vmin);
    margin:0;
}
@-webkit-keyframes change {
  0%, 20%, 100% {transform:translate3d(0,0,0);}
  25%, 45% {transform:translate3d(0,-33.3%,0);}
  50%, 70% {transform:translate3d(0,-66.6%,0);}
  75%, 95% {transform:translate3d(0,-33.3%,0);}
}
@-o-keyframes change {
  0%, 20%, 100% {transform:translate3d(0,0,0);}
  25%, 45% {transform:translate3d(0,-33.3%,0);}
  50%, 70% {transform:translate3d(0,-66.6%,0);}
  75%, 95% {transform:translate3d(0,-33.3%,0);}
}
@-moz-keyframes change {
  0%, 20%, 100% {transform:translate3d(0,0,0);}
  25%, 45% {transform:translate3d(0,-33.3%,0);}
  50%, 70% {transform:translate3d(0,-66.6%,0);}
  75%, 95% {transform:translate3d(0,-33.3%,0);}
}
@keyframes change {
  0%, 20%, 100% {transform:translate3d(0,0,0);}
  25%, 45% {transform:translate3d(0,-33.3%,0);}
  50%, 70% {transform:translate3d(0,-66.6%,0);}
  75%, 95% {transform:translate3d(0,-33.3%,0);}
}








/*BREAK*/






.project-page{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.project-page > div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-full-image {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 150vh;
    width: 100%;
    margin: 10vmin 0;
}
.project-full-image img {
    width: 100%;
    max-width: inherit;
    align-self: center;
    object-fit: cover;
}
.project-full-image.title {
    max-height: calc(100vh - 28px - 14vmin);
    margin: 0;
    /*overflow: hidden;*/
}
.project-full-image.title img {
    max-height: calc(100vh - 28px - 14vmin);
}
.project-full-image.full-bleed img{
    max-width: none;
    width: 140vw;
}
.project-full-image-ribbons{
    position: absolute;
    bottom: calc(-7px + -3.5vmin);
    left: calc(18px + 9vmin);
}
.project-full-image-ribbons p{
    margin: 0;
}
.project-full-image-ribbons img{
    height: calc(14px + 7vmin);
    max-width: calc(18px + 9vmin);
}
.project-full-image-ribbons a{
    display: inline-block;
    margin-right: calc(2px + 1vmin);
}
.project-heading{
    max-width: 150vh;
    text-align: center;
    box-sizing: border-box;
    padding: 0 10%;
    margin-bottom: 5vmin;
}
.project-heading h1{
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

.project-image-by-text {
    display: flex;
    max-width: 150vh;
    width: 100%;
    /*min-height: 80vmin;*/
    margin: 10vmin 0;
}
.project-image-by-text-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 0 5%;
}
.project-image-by-text-text.small-text {
    margin: 0 10%;
}
.project-image-by-text-image {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
}
.project-image-by-text-image img {
    width: 100%;
    align-self: center;
}
.project-image-by-text-image.bleed {
    width: 50%;
    flex: unset;
    display: flex;
}
.project-image-by-text-image.bleed.left {
    justify-content: flex-end;
}
.project-image-by-text-image.bleed.right {
    justify-content: normal;
}
.project-image-by-text-image.bleed img {
    min-width: 50vw;
}

.project-image-by-image {
    display: flex;
    max-width: 150vh;
    width: 100%;
    /*min-height: 80vmin;*/
    margin: 10vmin 0;
}
.project-image-by-image div {
    flex: 1;
}
.project-image-by-image div img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.project-image-by-image div + div {
    margin-left: 3%;
}

.project-columns {
    width: 100%;
    max-width: 150vh;
    /*min-height: 80vmin;*/
    margin: 10vmin 0;
    padding: 0 5%;
    box-sizing: border-box;
}
.project-columns-heading > h1, .project-columns-heading > h2 {
    text-align: center;
}
.project-columns-columns {
    display: flex;
}
.project-columns-column {
    flex: 1;
    padding: 0 1.5%;
}
.project-columns-column img {
    width: 100%;
}
.project-cycle-three {
    display: flex;
    max-width: 100vw;
    width: 90vh;
    margin: 10vmin 0;
    height: 90vh;
    max-height: 100vw;
    position: relative;
}
.project-cycle-arrow {
    position: absolute;
    height: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform-origin: bottom center;
    pointer-events: none;
    user-select: none;
    z-index: 0;
}
.project-cycle-stage {
    flex: 1;
    margin: 0 3%;
    z-index: 2;
}
.project-cycle-three .project-cycle-stage:nth-of-type(2) {
    align-self: flex-end;
}
.project-cycle-stage img {
    width: 100%;
    mix-blend-mode: multiply;
    z-index: 2;
}
.project-cycle-stage h2 {
    text-align: center;
}

.project-image-captioned {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 150vh;
    width: 100%;
    margin: 10vmin 0;
}
.project-image-captioned > img {
    width: 100%;
    max-height: 90vh;
    object-fit: contain;
}
.project-image-captioned-caption {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    box-sizing: border-box;
    padding: 0 3%;
    width: 100%;
}

.project-image-gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10vmin 0;
}
.project-image-gallery-gallery {
    width: 100%;
    height: 80vh;
    max-height: 80vw;
    overflow: hidden;
    white-space: nowrap;
    padding: calc(4px + 2vmin);
}
.project-image-gallery-slider {
    position: absolute;
    height: calc(100% - 8px - 4vmin);
    margin: 0 calc(4px + 2vmin);
}
.project-image-gallery img {
    display: inline-block;
    height: 100%;
    pointer-events: none;
}
.project-image-gallery img + img{
    margin-left: calc(2px + 1vmin);
}
.project-image-gallery-caption {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    box-sizing: border-box;
    padding: 0 3%;
    max-width: 150vh;
    width: 100%;
}
.project-image-gallery-caption *, .project-image-captioned-caption * {
    flex: 0 1 50%;
}
.project-image-gallery-caption *:last-child, .project-image-captioned-caption *:last-child {
    text-align: right;
}

.project-footer {
    display: flex;
    max-width: 150vh;
    width: 100%;
    justify-content: center;
    align-items: top;
    margin: 10vmin 0;
    flex-direction: column;
}
.project-footer-big {
    display: flex;
}
.project-footer-little {
    margin: 0 10%;
}
.project-footer-link {
    flex: 0 1 50%;
    text-decoration: none;
    color: inherit;
    margin: 0 10%;
}
.project-footer-link h2:first-child, .project-footer-link h3:first-child {
    font-weight: normal;
    margin-block-start: 0;
    margin-block-end: 0;
    opacity: .6;
}
.project-footer-link img {
    width: 15%;
    display: flex;
    flex-direction: column;
    opacity: .8;
}
.project-footer-link-prev {
    margin-bottom: 10%;
}
.project-footer-link-next img {
    width: 30%;
}

.page-image {
    -webkit-filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.4));
    filter:         drop-shadow(0px 0px 6px rgba(0,0,0,0.4));
}
.unfinished {
    margin-bottom: 0;
}

@media only screen and (max-width: 950px) {
    .project-page .details p {
        display: none;
    }
    .project-image-by-text-image {
        flex-shrink: 1;
    }
}
@media only screen and (max-width: 500px) {
    .project-page .big-details p {
        display: none;
    }
}








/*BREAK*/





.about-page{
    background-color: white;
    overflow: hidden;
    min-height: 100vh;
}
.about-page{
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.about-main{
    display: flex;
    max-width: 150vh;
    width: 100%;
}
.about-main-content {
    margin-top: ;
    flex: 1;
    margin: calc(18px + 9vmin + 9px + 4.5vmin) calc(18px + 9vmin) calc(4px + 2vmin + 50px) calc(18px + 9vmin);
}
.about-main-image {
    flex: 1;
}
.about-main-image img {
    max-width: 50vw;
    max-height: 100vh;
    display: block;
}
.about-footer {
    max-width: 150vh;
    width: 100%;
    padding: calc(9px + 4.5vmin) calc(18px + 9vmin);
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
}

@media only screen and (max-width: 120vh) {
    .about-main{
        flex-direction: column;
    }
    .about-main-image {
        justify-content: center;
        display: flex;
    }
    .about-main-image img {
        max-width: 100vw;
    }
    .about-footer {
        position: relative;
        bottom: unset;
    }
}
